import NestedNav from './nested-nav'
import uuid from '../shared/uuid'
import { debounce } from '../shared/debounce'

const LARGE_BREAKPOINT = 900
const HEADER_OPEN_CLASS = '-open'

class GlobalHeader {
  constructor(el) {
    this.el = el
    this.setVars()
    this.decorateNav()
    this.bindEvents()
    this.createNestedNavs()
  }

  setVars() {
    this.uuid = uuid()
    this.isNavOpen = false
    this.isAcctMenuOpen = false
    this.blurTimeout = null
    this.focusedNode = null
    this.isMobile = viewportWidth() < LARGE_BREAKPOINT
    this.acctMenuItems = this.el.querySelector('[data-js="acct-menu-items')
    this.acctMenuToggle = this.el.querySelector('[data-js="acct-menu-toggle"]')
    this.nav = this.el.querySelector('[data-js="nav"]')
    this.navList = this.el.querySelector('[data-js="nav-list"]')
    this.navToggle = this.el.querySelector('[data-js="toggle"]')
    this.nestedNavs = Array.from(this.el.querySelectorAll('[data-js="nested-nav"]'))
    this.navs = []
  }

  decorateNav() {
    this.navToggle.setAttribute('aria-controls', this.uuid)
    this.navToggle.setAttribute('aria-expanded', false)
  }

  undecorateNav() {
    this.navToggle.removeAttribute('aria-controls')
    this.navToggle.removeAttribute('aria-expanded')
  }

  bindEvents() {
    this.el.addEventListener('keydown', this.onKeyDown.bind(this))
    this.navToggle.addEventListener('click', this.toggleNav.bind(this))
    document.body.addEventListener('click', this.clickAway.bind(this), true)
    window.addEventListener('resize', debounce(this.onResize.bind(this), 300))
    this.navList.addEventListener('focus', this.onNavItemFocus.bind(this), true)
    this.navList.addEventListener('blur', this.onNavItemBlur.bind(this), true)

    if (this.acctMenuToggle) {
      this.acctMenuToggle.addEventListener('click', this.toggleAcctMenu.bind(this))
      document.addEventListener('click', this.checkOutsideAcctMenuClick.bind(this));
    }
  }

  onKeyDown(e) {
    if ((e.which || e.code) == 27 && (this.isNavOpen || this.isAcctMenuOpen)) {
      e.stopPropagation()
      e.preventDefault()

      if (this.isNavOpen) {
        this.closeNav()
        this.navToggle.focus()
      } else if (this.isAcctMenuOpen) {
        this.closeAcctMenu()
        this.acctMenuToggle.focus()
      }
    }
  }

  onResize(e) {
    let width = viewportWidth()

    if (this.isMobile && width >= LARGE_BREAKPOINT) {
      this.initDesktop()
    } else if (!this.isMobile && width < LARGE_BREAKPOINT) {
      this.initMobile()
    }

    e.preventDefault()
  }

  onNavItemFocus(e) {
    if (this.focusedNode && this.focusedNode.contains(e.target)) {
      return clearTimeout(this.blurTimeout)
    }

    setTimeout(_ => {
      const parent = this.focusedNode = e.target.parentNode

      if (parent.getAttribute('data-js') == 'nested-nav') {
        parent.classList.add('-focus')
      }
    })
  }

  onNavItemBlur(e) {
    const focused = this.focusedNode
    this.blurTimeout = setTimeout(_ => focused.classList.remove('-focus'), 50)
  }

  createNestedNavs() {
    this.nestedNavs.forEach(nav => this.navs.push(new NestedNav(nav, this)))
  }

  toggleAcctMenu(e) {
    this.isAcctMenuOpen ? this.closeAcctMenu() : this.openAcctMenu()
    e.preventDefault()
  }

  toggleNav(e) {
    this.isNavOpen ? this.closeNav() : this.openNav()
    e.preventDefault()
  }

  checkOutsideAcctMenuClick(e) {
    if (!this.isAcctMenuOpen) {
      return
    }

    const isClickInsideMenu = this.acctMenuItems.parentNode.contains(e.target);

    if (!isClickInsideMenu) {
      this.closeAcctMenu()
    }
  }

  closeAcctMenu() {
    this.acctMenuToggle.removeAttribute('aria-expanded')
    this.acctMenuItems.removeAttribute('aria-expanded')
    this.isAcctMenuOpen = false
  }

  openAcctMenu() {
    this.acctMenuToggle.setAttribute('aria-expanded', 'true');
    this.acctMenuItems.setAttribute('aria-expanded', 'true');
    this.isAcctMenuOpen = true
  }

  closeNav() {
    this.navs.forEach(nav => nav.close())
    this.el.classList.remove(HEADER_OPEN_CLASS)
    this.navToggle.setAttribute('aria-expanded', false)
    this.isNavOpen = false
  }

  openNav() {
    this.el.classList.add(HEADER_OPEN_CLASS)
    this.navToggle.setAttribute('aria-expanded', true)
    this.nav.focus()
    this.isNavOpen = true
  }

  initMobile() {
    this.decorateNav()
    this.isMobile = true
  }

  initDesktop() {
    this.undecorateNav()
    this.isMobile = false
  }

  clickAway(e) {
    if (this.isNavOpen) {
      // let the close button toggle the menu closed on its own
      if (e.target === this.navToggle) return

      // click is outside the header, eat the event and trigger the close
      if (!this.el.contains(e.target)) {
        e.stopPropagation()
        e.preventDefault()
        this.closeNav()
      }
    }

    // if (this.isAcctMenuOpen) {
    //   console.log(e.target, this.acctMenuToggle)

    //   // let the close button toggle the menu closed on its own
    //   if (e.target === this.acctMenuToggle) return

    //   // click is outside the header, eat the event and trigger the close
    //   if (!this.el.contains(e.target)) {
    //     e.stopPropagation()
    //     e.preventDefault()
    //     this.closeAcctMenu()
    //   }
    // }
  }
}

function viewportWidth() {
  let e = window
  let a = 'inner'

  if (!('innerWidth' in window)) {
    a = 'client'
    e = document.documentElement || document.body
  }

  return e[ a+'Width' ]
}

export default GlobalHeader
