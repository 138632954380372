class FileArchive {
  constructor(el) {
    this.el = el
    this.setVars()
    this.setup()
    this.bindEvents()
  }

  setVars() {
    this.downloadButton = this.el.querySelector('.download-selected')
    this.downloadAllButton = this.el.querySelector('.download-all')
    this.clearButton = this.el.querySelector('.clear-selection')

    this.checkboxes = [].slice.call(this.el.elements).filter(function(e) {
      return e.type === 'checkbox' && e.disabled !== true
    })
  }

  setup() {
    this.toggleButton()
  }

  bindEvents() {
    this.el.addEventListener('change', this.toggleButton.bind(this))
    this.el.addEventListener('submit', this.trackEvents.bind(this))
    this.downloadAllButton.addEventListener('click', this.checkAll.bind(this))
    this.clearButton.addEventListener('click', this.clearSelection.bind(this))
  }

  toggleButton() {
    var anyChecked = this.checkboxes.some(function(cb) {
      return cb.checked === true
    })

    this.downloadButton.setAttribute('aria-disabled', !anyChecked)
  }

  checkAll() {
    this.checkboxes.forEach(function(cb) {
      cb.checked = true
    })

    this.toggleButton()
  }

  clearSelection() {
    this.checkboxes.forEach(function(cb) {
      cb.checked = false
    })

    this.toggleButton()
  }

  trackEvents() {
    if (window.classroomResourceMetadata) {
      this.checkboxes.forEach(function(cb) {
        if (cb.checked) {
          dataLayer.push({
            'event': 'Content Interaction',
            'action': 'Download',
            'fileTitle': cb.parentElement.querySelector('a').innerHTML,
            ...window.classroomResourceMetadata
          })
        }
      })
    }
  }
}

export default FileArchive
