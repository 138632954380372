class TopicFilters {
  constructor(el) {
    this.el = el;
    this.setVars();
    this.setup();
    this.bindEvents();
  }

  setVars() {
    this.inputs = this.el.querySelectorAll('input, select');
    this.multiSelects = $('select[data-module=multi-select]');
    this.submit = this.el.querySelector('#q_submit_action');
  }

  setup() {
    if (this.submit) {
      this.submit.style.display = 'none';
    }

    this.inputs.forEach((i) => {
      if ((i.type === 'checkbox' || i.type === 'radio') && i.disabled) {
        i.parentNode.classList.add('-disabled');
      }
    });
  }

  bindEvents() {
    this.inputs.forEach((i) =>
      i.addEventListener('change', this.submitForm.bind(this))
    );
    this.multiSelects.on('change', this.submitForm.bind(this));
  }

  submitForm(e) {
    e.stopImmediatePropagation();
    this.el.submit();
  }
}

export default TopicFilters;
