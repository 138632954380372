import uuid from '../shared/uuid'

class NestedNav {
  constructor(el, globalNav) {
    this.el = el
    this.globalNav = globalNav
    this.setVars()
    this.decorate()
    this.bindEvents()
    this.openIfActive()
  }

  setVars() {
    this.isOpen = false
    this.nav = this.el.querySelector('.global-header__nav-list__nested')
  }

  bindEvents() {
    this.el.addEventListener('keydown', this.onKeyDown.bind(this), true)
    this.navToggle.addEventListener('click', this.toggleNav.bind(this))
  }

  /**
   * Adds dropdown buttons to nested navs
   */
  decorate() {
    let id = uuid()
    let link = this.nav.previousElementSibling
    let button = this.navToggle = document.createElement('button')
    button.className = 'global-header__nested-toggle'
    button.innerHTML = '<span class="__screenreader">Expand Subnav</span>'
    button.setAttribute('aria-controls', id)
    button.setAttribute('aria-expanded', false)
    link.parentNode.insertBefore(button, this.nav)
    this.nav.setAttribute('id', id)
  }

  toggleNav(e) {
    this.isOpen ? this.close() : this.open()
    e.preventDefault()
  }

  openIfActive() {
    if (this.el.classList.contains('-active')) {
      this.open()
    }
  }

  open() {
    this.navToggle.setAttribute('aria-expanded', true)
    this.el.classList.add('-open')
    this.isOpen = true
  }

  close() {
    this.navToggle.setAttribute('aria-expanded', false)
    this.el.classList.remove('-open')
    this.isOpen = false
  }

  onKeyDown(e) {
    if ((e.which || e.code) == 27 && this.isOpen) {
      e.stopPropagation()
      e.preventDefault()
      this.close()
      this.navToggle.focus()
    }
  }
}

export default NestedNav
