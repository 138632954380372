class DestroyDiscussionAttachment {
  constructor(el) {
    this.$el = $(el)
    this.$link = this.$el.find('[data-remote]')

    this.bindEvents()
  }

  bindEvents() {
    // note: `addEventListener` doesn't work for these UJS events, but jQuery does
    this.$link.on('ajax:send', (event) => {
      console.log('destroy - send', event)
      this.$el.addClass('-remote-loading')
    });

    this.$link.on('ajax:success', (event) => {
      console.log('destroy - success', event)
      this.$el.remove()
    });

    this.$link.on('ajax:error', (event) => {
      console.error('destroy - error', event)
    });
  }
}

export default DestroyDiscussionAttachment
