class TabGroup {
  constructor(el) {
    this.el = el

    try {
      this.tabs = Array.from(this.el.querySelectorAll('[role="tab"]'))
      this.panels = Array.from(this.el.querySelectorAll('[role="tabpanel"]'))
      this.mqWide = window.matchMedia('(min-width: 680px)')
      this._handleTabClick = this.handleTabClick.bind(this)

      if (this.tabs.length === 0 || this.panels.length === 0) {
        throw new Error('Missing tabs or panels')
      }

      if (this.tabs.length !== this.panels.length) {
        throw new Error(`The number of tabs (${this.tabs.length}) and panels (${this.panels.length}) do not match`)
      }

      this.mqWide.matches && this.setup()
      this.bindEvents()
    } catch (e) {
      console.warn('Error setting up TabGroup for element', el)
      console.error(e)
    }
  }

  bindEvents() {
    this.mqWide.addEventListener('change', (e) => {
      if (e.matches) {
        this.setup()
      } else {
        this.teardown()
      }
    })
  }

  setup() {
    this.selectTab(this.tabs[0])

    this.tabs.forEach(tab => {
      tab.addEventListener('click', this._handleTabClick)
    })
  }

  teardown() {
    this.tabs.forEach(tab => {
      tab.removeAttribute('aria-selected')
      tab.removeEventListener('click', this._handleTabClick)
    })

    this.panels.forEach(panel => {
      panel.removeAttribute('hidden')
    })
  }

  selectTab(el) {
    this.tabs.forEach(tab => {
      tab.removeAttribute('aria-selected')
    })

    el.setAttribute('aria-selected', 'true')

    this.panels.forEach(panel => {
      panel.setAttribute('hidden', 'true')
    })

    this.panels.find(panel => panel.getAttribute('aria-labeledby') === el.id).removeAttribute('hidden')
  }

  handleTabClick(e) {
    e.preventDefault()
    this.selectTab(e.currentTarget)
  }
}

export default TabGroup
