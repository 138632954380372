class HomeHero {
  constructor(el) {
    this.el = el
    this.setVars()
    this.setup()
    this.bindEvents()

    // only for large screens
    // this.mqLarge.matches && this.setup()
    // this.mqLarge.addListener(this.handleMqChange)
  }

  setVars() {
    // this.mqLarge = window.matchMedia('(min-width: 900px')
    this.buttons = Array.from(this.el.querySelectorAll('[data-js="button"]'))
  }

  setup() {
    // open the first item
    this.toggleItem(this.buttons[0], true)

    // close remaining items
    this.buttons.slice(1).forEach(button => {
      this.toggleItem(button, false)
    })
  }

  bindEvents() {
    this.buttons.forEach(button => {
      button.addEventListener('click', this.handleClick.bind(this))
    })
  }

  handleMqChange({ matches }) {
    matches ? this.bindEvents() : this.cleanUp()
  }

  handleClick(e) {
    const openItem = this.el.querySelector('[aria-expanded="true"]')

    // don't close any items
    if (e.currentTarget === openItem) return

    // close the open item
    this.toggleItem(openItem, false)

    // open the clicked item
    this.toggleItem(e.currentTarget, true)
  }

  toggleItem(button, show) {
    const targetId = button.getAttribute('aria-controls')
    const targetEl = this.el.querySelector(`#${targetId}`)

    button.setAttribute('aria-expanded', show)

    if (show) {
      targetEl.parentNode.classList.add('-active')
      targetEl.removeAttribute('hidden')
      targetEl.focus()
    } else {
      targetEl.parentNode.classList.remove('-active')
      targetEl.setAttribute('hidden', '')
    }
  }
}

export default HomeHero
