import 'what-input'

// details in https://vitejs.dev/guide/features.html#glob-import
const modules = import.meta.glob('../javascripts/modules/*.js', {
  import: 'default',
  eager: true
})
const moduleElements = document.querySelectorAll('[data-module]')

for (var i = 0; i < moduleElements.length; i++) {
  const el = moduleElements[i]
  const name = el.getAttribute('data-module')
  const modulePath = Object.keys(modules).find(path => path.indexOf(name) !== -1)
  if (modules[modulePath]) {
    const Module = modules[modulePath]
    new Module(el)
  } else {
    console.error('missing module for el', el, modulePath)
  }
}

import objectFitImages from 'object-fit-images'
const coverImages = document.querySelectorAll([
  '.resources-hero__image img',
  '.card__image.-full'
]);
objectFitImages(coverImages, { watchMQ: true });
