class PlaceholderSelect {
  constructor(el) {
    this.el = el
    this.bindEvents()
    this.el.dispatchEvent(new Event('change'))
  }

  bindEvents() {
    this.el.addEventListener('change', this.updateState.bind(this))
  }

  updateState(e) {
    e.target.classList[e.target.value == '' ? 'add' : 'remove']('-empty')
  }
}

export default PlaceholderSelect
